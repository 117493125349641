import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import React from 'react';

function UserCell({
  value,
}: {
  value: {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    username?: string;
  };
}) {
  return <CenteredCell>{value ? displayName(value) : '-'}</CenteredCell>;
}

UserCell.displayName = 'UserCell';

export default UserCell;
