import { UserFilter as SchemaUserFilter } from '@asaprint/asap/schema.client.types.js';
import InputFilter from '@engined/client/components/table/InputFilter.js';
import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import React from 'react';

const UserFilter: FilterComponent<any, SchemaUserFilter> = (props) => {
  return <InputFilter {...props} />;
};

UserFilter.displayName = 'UserFilter';

UserFilter.filterToGraphQLVariable = (value) => {
  return {
    OR: [
      {
        fullName: {
          ilike: `%${value.trim()}%`,
          unaccent: true,
        },
      },
      {
        username: {
          ilike: `%${value.trim()}%`,
          unaccent: true,
        },
      },
    ],
  };
};

export default React.memo(UserFilter);
